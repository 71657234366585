import React, { useState, useRef, useEffect } from "react"
// import 'react-schedule-job/dist/index.css'
// import './index.css';
import { connect, useStore } from "react-redux"
import {
    Flex,
    Heading,
    TableBody,
    View,
    Item,
    TableHeader,
    Button,
    TableView,
    SearchField,
    ActionGroup,
    Text,
    Column,
    IllustratedMessage,
    Content,
    Row,
    Cell,
    Badge,
    DialogTrigger,
    Dialog,
    ActionButton,
    ProgressCircle,
    Divider,
    ComboBox,
    MenuTrigger,
    Menu,
} from "@adobe/react-spectrum"
import {
    fetchInstance,
    getDefaultGroupsConfig,
    getAllAttributeListAndValues,
} from "../../utilities/api/phoenix-api"
import { getUserProfileReport } from "../../utilities/api/reports-api"
// import Delete from '@spectrum-icons/workflow/Delete';
// import Draw from '@spectrum-icons/workflow/Draw';
// import Refresh from "@spectrum-icons/workflow/Refresh"
// import { notify } from '../../../constants/constants';
import { renderEmptyState } from "../../utilities/components/CommonUseComponents"
import ChevronDown from "@spectrum-icons/workflow/ChevronDown"
import Filter from "@spectrum-icons/workflow/Filter"
// import NotFound from '@spectrum-icons/illustrations/NotFound';
import { NotFoundIconSvg } from "../../utilities/components/Icons"
import Search from "@spectrum-icons/workflow/Search"

const UserProfile = () => {
    const columns = [
        { name: "Name", key: "name" },
        { name: "Group", key: "group" },
        { name: "Group Supervisor", key: "groupSupervisor" },
    ]

    const columnsAdmin = [
        { name: "Name", key: "name" },
        { name: "Group", key: "group" },
        { name: "Group Supervisor", key: "groupSupervisor" },
    ]

    const [isLoading, setIsLoading] = useState(true)
    const [allInstances, setAllInstances] = useState([])
    const [selectedInstance, setSelectedInstance] = useState()
    const [selectedType, setSelectedType] = useState()
    const [allValues, setAllValues] = useState()
    const [selectedValues, setSelectedValues] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [allProficiencies, setAllProficiencies] = useState([])
    const [allAttributeNames, setAllAttributeNames] = useState([])
    const [selectedAttributeName, setSelectedAttributeName] = useState()
    const [selectedAttributeValue, setSelectedAttributeValue] = useState()
    // const [allAttributeValues, setAllAttributeValues] = useState([])
    // const [searchTerm, setSearchTerm] = useState("")
    console.log("allProficiencies", allProficiencies)
    console.log("allAttributeNames", allAttributeNames)
    // console.log("allAttributeValues", allAttributeValues);

    useEffect(() => {
        getAllInstances()
        getAllQueues()
    }, [])

    useEffect(() => {
        if (selectedInstance) {
            getAllProficiencies()
        }
    }, [selectedInstance])

    const getAllProficiencies = () => {
        setIsLoading(true)
        getAllAttributeListAndValues()
            .then((response) => {
                setIsLoading(false)
                const attributeNames = Object.keys(
                    response[selectedInstance]
                )?.map((item) => ({ name: item }))
                const attributeValues = Object.values(
                    response[selectedInstance]
                )
                setAllProficiencies(response[selectedInstance])
                setAllAttributeNames(attributeNames)
                // setAllAttributeValues(attributeValues)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
            })
    }

    const getAllInstances = () => {
        setIsLoading(true)
        fetchInstance()
            .then((response) => {
                setIsLoading(false)
                setAllInstances(response)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
            })
    }

    function sortDataByName(data) {
        return data?.sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
        })
    }

    const getAllQueues = () => {
        // if (instance) {
        setIsLoading(true)
        getDefaultGroupsConfig()
            .then((response) => {
                setIsLoading(false)
                setAllValues(response)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
            })
        // }
    }

    // const handleSearchChange = (value) => {
    //     setSearchTerm(value)
    //     const filteredData = searchResults.filter((item) =>
    //         item.name.toLowerCase().includes(value.toLowerCase())
    //     )
    //     setFilteredStructuredData(filteredData)
    // }

    const handleFilter = () => {
        setIsLoading(true)
        // const selectedValuesArray = Array.from(selectedValues)
        const fetchInstance = allInstances.find(
            (item) => item.instance_id === selectedInstance
        )
        const queueObj = {
            instance: fetchInstance,
            queueIds: [selectedValues],
        }

        const proficiencyObj = {
            instance: fetchInstance,
            proficiencies: [
                {
                    attributeName: selectedAttributeName,
                    attributeValue: selectedAttributeValue,
                },
            ],
        }
        const type = selectedType === "queue" ? "queues" : "proficiencies"
        const data = selectedType === "queue" ? queueObj : proficiencyObj
        getUserProfileReport(type, data)
            .then((response) => {
                setSearchResults(response[0].users)
            })
            .catch((error) => {
                console.log("error", error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleClear = () => {
        resetData()
    }

    const resetData = () => {
        setSelectedInstance(null)
        setSelectedType(null)
        setSelectedValues(null)
        setSearchResults([])
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "name":
                return <Cell>{`${item.firstName} ${item.lastName}`}</Cell>
            case "group":
                return <Cell>{item?.groupName || "-"}</Cell>
            case "groupSupervisor":
                return (
                    <Cell>{item?.isGroupSupervisor ? "Yes" : "No" || "-"}</Cell>
                )
        }
    }

    const fetchProficiencies = () => {
        const proficiencies = allProficiencies[
            selectedAttributeName
        ]?.map((item) => ({ name: item }))
        return proficiencies
    }

    return (
        <>
            {isLoading ? (
                <div
                    className={isLoading ? "display-block" : "display-none"}
                    style={{
                        paddingTop: "20%",
                        paddingLeft: "55%",
                        position: "absolute",
                        zIndex: "10",
                        // width: "100%",
                    }}
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="M"
                    />
                </div>
            ) : null}
            <div align="center">
                <Heading variant="pageTitle">User Profile</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    height: "60vh",
                    width: "100%",
                    display: "flex",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        width: "350px",
                        minWidth: "350px",
                        maxWidth: "350px",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        borderRight: "1px solid #e0e0e0",
                    }}
                >
                    <Flex direction="column" gap={10}>
                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Heading
                                level={6}
                                width="100px"
                                UNSAFE_style={{ color: "#999999" }}
                            >
                                Instance
                            </Heading>
                            <ComboBox
                                defaultItems={allInstances || []}
                                selectedKey={selectedInstance}
                                onSelectionChange={setSelectedInstance}
                                width="calc(100% - 100px)"
                            >
                                {(item) => (
                                    <Item key={item.instance_id}>
                                        {item.instance_alias}
                                    </Item>
                                )}
                            </ComboBox>
                        </Flex>

                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            {/* <Text width="100px" color="gray">
                                Type
                            </Text> */}
                            <Heading
                                level={6}
                                width="100px"
                                UNSAFE_style={{ color: "#999999" }}
                            >
                                Type
                            </Heading>
                            <ComboBox
                                defaultItems={[
                                    { name: "Queue", key: "queue" },
                                    { name: "Proficiency", key: "proficiency" },
                                ]}
                                selectedKey={selectedType}
                                onSelectionChange={setSelectedType}
                                width="calc(100% - 100px)"
                                isDisabled={!selectedInstance}
                            >
                                {(item) => (
                                    <Item key={item.key}>{item.name}</Item>
                                )}
                            </ComboBox>
                        </Flex>

                        {/* Conditional Rendering for Proficiency or Queue */}
                        {selectedType === "proficiency" ? (
                            <>
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Heading
                                        level={6}
                                        width="100px"
                                        UNSAFE_style={{ color: "#999999" }}
                                    >
                                        Attribute Name
                                    </Heading>
                                    <ComboBox
                                        defaultItems={allAttributeNames || []}
                                        selectedKey={selectedAttributeName}
                                        onSelectionChange={
                                            setSelectedAttributeName
                                        }
                                        width="calc(100% - 100px)"
                                        isDisabled={!selectedInstance}
                                    >
                                        {(item) => (
                                            <Item key={item.name}>
                                                {item.name}
                                            </Item>
                                        )}
                                    </ComboBox>
                                </Flex>
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Heading
                                        level={6}
                                        width="100px"
                                        UNSAFE_style={{ color: "#999999" }}
                                    >
                                        Proficiency
                                    </Heading>
                                    <ComboBox
                                        defaultItems={
                                            fetchProficiencies() || []
                                        }
                                        selectedKey={selectedAttributeValue}
                                        onSelectionChange={
                                            setSelectedAttributeValue
                                        }
                                        width="calc(100% - 100px)"
                                        isDisabled={
                                            !selectedInstance || !selectedType
                                        }
                                    >
                                        {(item) => (
                                            <Item key={item.name}>
                                                {item.name}
                                            </Item>
                                        )}
                                    </ComboBox>
                                </Flex>
                            </>
                        ) : (
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Heading
                                    level={6}
                                    width="100px"
                                    UNSAFE_style={{ color: "#999999" }}
                                >
                                    Queue
                                </Heading>
                                <ComboBox
                                    defaultItems={
                                        (allValues &&
                                            sortDataByName(
                                                allValues[selectedInstance]
                                            )) ||
                                        []
                                    }
                                    selectedKey={selectedValues}
                                    onSelectionChange={setSelectedValues}
                                    width="calc(100% - 100px)"
                                    isDisabled={
                                        !selectedInstance || !selectedType
                                    }
                                >
                                    {(item) => (
                                        <Item key={item.key}>{item.name}</Item>
                                    )}
                                </ComboBox>
                            </Flex>
                        )}

                        <Flex direction="column" gap={10} marginTop={10}>
                            <Button
                                variant="cta"
                                style="fill"
                                width="100%"
                                isDisabled={!selectedInstance || !selectedType || !selectedValues || !selectedAttributeName || !selectedAttributeValue} 
                                onPress={handleFilter}
                            >
                                <Search marginEnd="5px" /> Search
                            </Button>
                            <Button
                                variant="negative"
                                style="outline"
                                width="100%"
                                onPress={handleClear}
                            >
                                Clear
                            </Button>
                        </Flex>
                    </Flex>
                </div>
                {searchResults.length > 0 && (
                    <TableView
                        UNSAFE_style={{ marginLeft: "10px" }}
                        aria-label="Care Roles"
                        // selectionMode="multiple"
                        // selectedKeys={selectedAttributes}
                        // onSelectionChange={setSelectedAttributes}
                        height={"55vh"}
                        width={"100%"}
                        density="compact"
                        overflowMode="wrap"
                        // disabledKeys={["1"]}
                    >
                        <TableHeader columns={columns}>
                            {(column) => (
                                <Column
                                    key={column.key}
                                    align={
                                        column.key === "actions"
                                            ? "end"
                                            : "start"
                                    }
                                    width={
                                        column.key === "attributeValue"
                                            ? "60%"
                                            : column.key === "actions"
                                            ? "16.5%"
                                            : "auto"
                                    }
                                    minWidth={
                                        ["permissions"].indexOf(column.key) > -1
                                            ? "40%"
                                            : "10%"
                                    }
                                >
                                    {column.name}
                                </Column>
                            )}
                        </TableHeader>
                        <TableBody items={searchResults}>
                            {(item) => (
                                <Row key={item.ldap}>
                                    {(columnKey) => getCell(columnKey, item)}
                                </Row>
                            )}
                        </TableBody>
                    </TableView>
                )}
                {!isLoading && searchResults.length === 0 && (
                    <View width={"100%"}>
                        <IllustratedMessage>
                            {/* <NotFound /> */}
                            <NotFoundIconSvg />
                            <Heading>No results found</Heading>
                            {/* <Content>No results found</Content> */}
                        </IllustratedMessage>
                    </View>
                )}
            </div>
            {/* </div> */}
        </>
    )
}

export default UserProfile
