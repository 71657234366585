import "./style.css"
import React, { useEffect, useState, useMemo } from "react"
import {
    Flex,
    Heading,
    ProgressCircle,
    View,
    SearchField,
    NumberField,
    Button,
    ActionGroup,
    Item,
    TableView,
    TableHeader,
    Row,
    Column,
    TableBody,
    Cell,
    ActionButton,
    DialogTrigger,
    Dialog,
    Divider,
    Content,
    Text,
    TextField,
    Footer,
    IllustratedMessage,
    Header,
    TooltipTrigger,
    Tooltip,
    AlertDialog,
    Picker,
    ComboBox,
    TextArea,
    DatePicker,
    LabeledValue,
    StatusLight,
} from "@adobe/react-spectrum"
import { connect } from "react-redux"
import TableViewContainer from "../../utilities/components/TableViewContanire"
import DevicePreview from "@spectrum-icons/workflow/DevicePreview"
import {
    getCaseContactList,
    getCaseContactDetail,
    searchCaseContact,
    reRouteCaseContact,
    getProductIssueReasons,
    rerouteWithUpdatedData,
} from "../../utilities/api/srsRules-api"
import {
    SRSRoles,
    caseHistoryNumericIDLength,
    caseHistoryRequestType,
    notify,
} from "../../constants/constants"
import Copy from "@spectrum-icons/workflow/Copy"
import { renderEmptyState } from "../../utilities/components/CommonUseComponents"
import { getDefaultInstance } from "../../utilities/api/admin-api"
import Refresh from "@spectrum-icons/workflow/Refresh"
import { DownArrow, UpArrow } from "../../utilities/components/Icons"
import { Status } from "@okta/okta-auth-js"
import { enforceUpdateApi } from "../../utilities/api/phoenix-api"
import { use } from "react"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        rolesAndPermissions: state.login.rolesAndPermissions,
    }
}

const CaseHistory = ({ user, rolesAndPermissions }) => {
    const havingDeletePermission = rolesAndPermissions["SRS"]?.delete?.filter(
        (ele) => ele.subService === "CASE HISTORY"
    )[0]?.resources.type
    const havingEditPermission = rolesAndPermissions["SRS"]?.edit?.filter(
        (ele) => ele.subService === "CASE HISTORY"
    )[0]?.resources.type
    // const havingViewPermission = rolesAndPermissions["SRS"]?.view?.filter((ele) => ele.subService === "CASE HISTORY")[0]?.resources.type
    const havingAddPermission = rolesAndPermissions["SRS"]?.add?.filter(
        (ele) => ele.subService === "CASE HISTORY"
    )[0]?.resources.type
    const [isLoading, setIsLoading] = useState(false)
    const [currentText, setCurrentText] = useState("")
    const [filteredList, setFilteredList] = useState([])
    const [prefferedTimeRange, setPrefferedTimeRange] = useState("hour")
    const [prefferedNumberRange, setPrefferedNumberRange] = useState(1)
    const [selectedCaseContactDetail, setSelectedCaseContactDetail] = useState(
        {}
    )
    // useEffect(() => {
    //     console.log({ selectedCaseContactDetail })
    // }, [selectedCaseContactDetail])

    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [selectedCaseId, setSelectedCaseId] = useState("")
    const [generatedCaseId, setGeneratedCaseId] = useState("")
    const [updatedContactId, setUpdatedContactId] = useState("")
    const [previousRequestType, setPreviousRequestType] = useState(
        caseHistoryRequestType.durationRequest
    )
    const [issueReasonList, setIssueReasonList] = useState([])
    const [regionList, setRegionList] = useState([])
    const [priorityList, setPriorityList] = useState([])
    const [levelList, setLevelList] = useState([])
    const [instance, setInstance] = useState()
    const [nextToken, setNextToken] = useState("")
    // const [textArea, setTextArea] = useState('');

    // Sorting in table
    const [sortDescriptor, setSortDescriptor] = useState({
        column: null,
        direction: "ascending",
    })

    // // Function to get access permissions
    // function getAccessPermissions(tabName) {
    //     console.log("permission",newPermissions.add)
    //     return {
    //         add: newPermissions.add?.some((value)=>value.subService==tabName),
    //         clone: newPermissions.clone?.some((value)=>value.subService==tabName),
    //         delete: newPermissions.delete?.some((value)=>value.subService==tabName),
    //         edit: newPermissions.edit?.some((value)=>value.subService==tabName),
    //         enforceUpdate: newPermissions.enforceUpdate?.some((value)=>value.subService==tabName),
    //         view: newPermissions.view?.some((value)=>value.subService==tabName),
    //     };
    // }
    const handleSortChange = (columnKey) => {
        setSortDescriptor((prevSortDescriptor) => {
            const isSameColumn = prevSortDescriptor.column == columnKey
            return {
                column: columnKey,
                direction: isSameColumn
                    ? prevSortDescriptor.direction === "ascending"
                        ? "descending"
                        : "ascending"
                    : "ascending",
            }
        })
    }
    const [selectedStatus, setSelectedStatus] = useState("Select Status")
    const statusList = [
        { name: "Waiting in queue" },
        { name: "Answered and ended" },
        { name: "Unanswered and ended" },
        { name: "Answered" },
    ]
    const statusColor = {
        "Waiting in queue": "notice",
        "Answered and ended": "positive",
        "Unanswered and ended": "negative",
        Answered: "info",
    }
    const columns = [
        { name: "Case Id", key: "caseid", minWidth: 190 },
        { name: "Product name", key: "productname", minWidth: 240 },
        { name: "Connect Queue", key: "queue", minWidth: 300 },
        { name: "Status", key: "status", minWidth: 230 },
        { name: "T3 Id", key: "t3caseid", minWidth: 180 },
        { name: " Case Contact Id", key: "contactId", minWidth: 300 },
        { name: "Case answered By", key: "contact_answered_by", minWidth: 180 },
        {
            name: " Case Contact time",
            key: "contact_creation_time",
            minWidth: 200,
        },
        {
            name: " Case Disconnect time",
            key: "contact_disconnect_time",
            minWidth: 200,
        },
    ]
    // Date Filter state
    const [dateFilters, setDateFilters] = useState({
        contact_creation_time: null,
        contact_disconnect_time: null,
    })

    // Filter state
    const [filters, setFilters] = useState(
        columns.reduce((acc, column) => ({ ...acc, [column.key]: "" }), {})
    )

    // Handle column-specific search
    const handleFilterChange = (key, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: value,
        }))
    }

    // Handle date picker change
    const handleDateChange = (key, value) => {
        setDateFilters((prevDateFilters) => ({
            ...prevDateFilters,
            [key]: value,
        }))
    }

    const normalizeDate = (dateObj) => {
        if (dateObj) {
            return new Date(dateObj.year, dateObj.month - 1, dateObj.day)
        }
        return null
    }

    const filteredData = useMemo(() => {
        const isDateColumn = (key) =>
            ["contact_creation_time", "contact_disconnect_time"].includes(key)

        const filtered = filteredList.filter((row) => {
            const columnFilterPass = columns.every((column) => {
                const { key } = column
                if (!isDateColumn(key)) {
                    const filterValue = filters[key] || ""
                    const cellValue = row[key]?.toString().toLowerCase() || ""
                    if (!cellValue.includes(filterValue.toLowerCase())) {
                        return false
                    }
                }

                if (isDateColumn(key)) {
                    const filterDate = normalizeDate(dateFilters[key])
                    const cellDate = new Date(row[key])

                    if (
                        filterDate &&
                        cellDate.toDateString() !== filterDate.toDateString()
                    ) {
                        return false
                    }
                }

                return true
            })

            const statusFilterPass =
                !selectedStatus ||
                selectedStatus === "Select Status" ||
                row.status === selectedStatus

            return columnFilterPass && statusFilterPass
        })

        if (sortDescriptor.column) {
            filtered.sort((a, b) => {
                const { column, direction } = sortDescriptor
                const aValue = a[column]
                const bValue = b[column]

                const aStr = aValue?.toString() || ""
                const bStr = bValue?.toString() || ""

                const aNumeric = parseInt(aStr.replace(/\D/g, ""), 10)
                const bNumeric = parseInt(bStr.replace(/\D/g, ""), 10)

                if (!isNaN(aNumeric) && !isNaN(bNumeric)) {
                    return direction === "ascending"
                        ? aNumeric - bNumeric
                        : bNumeric - aNumeric
                } else {
                    const comparison = aStr.localeCompare(bStr, undefined, {
                        numeric: true,
                    })
                    return direction === "ascending" ? comparison : -comparison
                }
            })
        }
        return filtered
    }, [filters, dateFilters, filteredList, sortDescriptor, selectedStatus])

    const teamMembers = [
        { key: "tarunt", name: "Tarun Tyagi" },
        { key: "rchaudha", name: "Ravikant Chaudhary" },
        { key: "richay", name: "Richa Yadav" },
        { key: "hmudgal", name: "Hemant Kumar Mudgal" },
        { key: "manoverm", name: "Manoj kumar Verma" },
        { key: "apoorvshukla", name: "Apoorv Shukla" },
    ]

    const remapIssueReasonDataToPickerInput = (array = []) => {
        return array.map((ele) => ({
            id: `${ele?.code}-${ele?.text}`,
            name: ele?.text,
        }))
    }
    const remapIssueReasonDataToIssuePickerInput = (array = []) => {
        return array.map((ele) => ({
            id: `${ele?.issue_reason_code}-${ele?.alternate_name}`,
            name: ele?.issue_reason_name,
        }))
    }

    function decodeBase64(base64String) {
        if (!base64String) return ""
        const decodedString = atob(base64String)
        return decodedString.replace(/^"|"$/g, "")
    }

    const getCaseConnectDetails = (caseId, contactId, productCode = "") => {
        setIsLoading(true)
        setSelectedCaseId(caseId)
        //Handle requests both
        Promise.allSettled([
            getCaseContactDetail(caseId, contactId),
            getProductIssueReasons(productCode),
        ])
            .then((resp) => {
                const [caseContactDetail, productIssueReason] = resp
                if (
                    caseContactDetail.status == "fulfilled" &&
                    productIssueReason.status == "fulfilled"
                ) {
                    const [selectedProduct] = caseContactDetail.value
                    const decodedDescription = decodeBase64(
                        selectedProduct?.case_description_base64
                    )
                    const updatedNAMEDSUPPORTENGINEER = selectedProduct?.NAMEDSUPPORTENGINEER.split(
                        "@"
                    )[0]
                    const {
                        productname,
                        contact_creation_time,
                        contact_answered_time,
                        contact_disconnect_time,
                        contact_inQueue_time,
                        contact_answered_by,
                    } = filteredData.find(
                        (value) =>
                            value.contactId == selectedProduct.aws_contact_id
                    )
                    const updatedProduct = {
                        ...selectedProduct,
                        case_description_base64: decodedDescription,
                        NAMEDSUPPORTENGINEER: updatedNAMEDSUPPORTENGINEER,
                        contact_creation_time,
                        contact_answered_time,
                        contact_disconnect_time,
                        contact_inQueue_time,
                        productname,
                        contact_answered_by,
                    }
                    let issueReason= selectedProduct?.PRODUCTNAME ;
                    // console.log("issueReason",issueReason)
                    setSelectedCaseContactDetail(updatedProduct)
                    const {
                        PRIORITYTEXT,
                        REGIONTEXT,
                        ISSUEREASONTEXT,
                        LEVEL,
                    } = JSON.parse(productIssueReason.value)
                    setIssueReasonList(
                        remapIssueReasonDataToIssuePickerInput(
                            ISSUEREASONTEXT.find(
                                (ele) =>
                                    issueReason.toLowerCase().includes(ele.productFamilyName.toLowerCase()) ||
                                issueReason.toLowerCase().includes(ele.productFamilyAlternateName.toLowerCase())
                            )?.issueReasons
                        )
                    )
                    setRegionList(remapIssueReasonDataToPickerInput(REGIONTEXT))
                    setPriorityList(
                        remapIssueReasonDataToPickerInput(PRIORITYTEXT)
                    )
                    setLevelList(remapIssueReasonDataToPickerInput(LEVEL))
                } else {
                    notify("Something Went Wrong!", "error")
                }
            })
            .catch((err) => {
                console.log({ err })
                notify(err?.message || "Something Went Wrong!", "error")
            })
            .finally(() => {
                setIsLoading(false)
                setShowDetailsModal(true)
            })
    }
    const formatDateValue = (value) => {
        if (value) {
            const date = new Date(value)
            const options = {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            }

            return date.toLocaleString("en-US", options)
        } else return "-"
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup>
                            <Item key="view">
                                <DevicePreview />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "contact_creation_time":
                return (
                    <Cell>
                        {item[columnKey] &&
                        !(typeof item[columnKey] !== "string")
                            ? formatDateValue(item[columnKey])
                            : "-"}
                    </Cell>
                )
            case "contact_disconnect_time":
                return (
                    <Cell>
                        {item[columnKey] &&
                        !(typeof item[columnKey] !== "string")
                            ? formatDateValue(item[columnKey])
                            : "-"}
                    </Cell>
                )
            case "caseid":
                return (
                    <Cell>
                        <ActionButton
                            UNSAFE_className="caseID-text"
                            isQuiet
                            // isDisabled={!allPermissions.view}
                            onPress={() =>
                                getCaseConnectDetails(
                                    item?.caseid,
                                    item?.contactId,
                                    item?.productcode
                                )
                            }
                        >
                            {item[columnKey]}
                        </ActionButton>
                    </Cell>
                )
            case "contactId":
                return (
                    <Cell>
                        <div
                            className="caseID-text button-styling-remove pointer"
                            onClick={() => copyData(item[columnKey])}
                        >
                            <Text>{item[columnKey] || "-"}</Text>
                        </div>
                    </Cell>
                )
            case "status":
                return (
                    <Cell>
                        <StatusLight variant={statusColor[item[columnKey]]}>
                            {item[columnKey] || "-"}
                        </StatusLight>
                    </Cell>
                )
            default:
                return <Cell>{item[columnKey] || "-"}</Cell>
        }
    }

    const getCaseHistoryData = async (instanceValue) => {
        try {
            const instaceExists = instanceValue || instance
            if (instaceExists) {
                setIsLoading(true)
                setPreviousRequestType(
                    caseHistoryRequestType.durationRequest,
                    instaceExists,
                    "",
                    user.userid
                )
                getCaseContactList(
                    prefferedTimeRange,
                    prefferedNumberRange,
                    instaceExists,
                    null,
                    user.userid
                )
                    .then((resp) => {
                        setNextToken(resp.nextToken || null)

                        setFilteredList(
                            resp.contacts.sort(
                                (a, b) =>
                                    new Date(b.contact_time) -
                                    new Date(a.contact_time)
                            )
                        )
                    })
                    .catch((err) => {
                        console.log({ err })
                        notify(err?.message || "Something Went Wrong!", "error")
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }
        } catch (error) {
            console.log({ error })
            notify(JSON.stringify(error), "error")
        }
    }

    const retryCaseConnect = () => {
        setIsLoading(true)
        const { aws_contact_id, srs_case_id } = selectedCaseContactDetail
        let updatedAttributes = {}
        for (let key in selectedCaseContactDetail) {
            if (key !== "aws_contact_id" && key !== "srs_case_id") {
                Object.assign(updatedAttributes, {
                    [key]:
                        key === "NAMEDSUPPORTENGINEER"
                            ? `${selectedCaseContactDetail[key]}@adobe.com`
                            : selectedCaseContactDetail[key],
                })
            }
        }

        // RegionCode, PriorityCode
        const [REGIONCODE] = regionList
            .find(
                (region) =>
                    region.name === selectedCaseContactDetail?.REGIONTEXT
            )
            ?.id.split("-") || [""]
        const [PRIORITYCODE] = priorityList
            .find(
                (region) =>
                    region.name === selectedCaseContactDetail?.PRIORITYTEXT
            )
            ?.id.split("-") || [""]

        updatedAttributes = { ...updatedAttributes, REGIONCODE, PRIORITYCODE }

        const result = {
            aws_contact_id,
            case_id: srs_case_id,
            updatedAttributes,
        }
        rerouteWithUpdatedData(user.userid, result)
            .then((resp) => {
                const { response } = resp
                setUpdatedContactId(response.split(" ")[5])
                notify(response || "Re-Route Success!", "success")
            })
            .catch((err) => {
                notify(err?.message || "Something Went Wrong!", "error")
            })
            .finally(() => {
                setIsLoading(false)
                setShowDetailsModal(false)
            })
    }

    const prefixPaddedValues = (numeric, category = "E") => {
        const totalLength = caseHistoryNumericIDLength[category]
        return numeric.padStart(totalLength, "0")
    }

    const getSearchResults = (caseId) => {
        setIsLoading(true)
        setPreviousRequestType(caseHistoryRequestType.searchFilter)
        searchCaseContact(caseId)
            .then((resp) => {
                setFilteredList(
                    resp.sort(
                        (a, b) =>
                            new Date(b.contact_time) - new Date(a.contact_time)
                    )
                )
            })
            .catch((err) => {
                notify(JSON.stringify(err), "error")
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const keyEventTrigger = (e) => {
        const { key } = e
        let fullSearchValue = ""
        if (key == "Enter") {
            const isHyphenated = currentText.includes("-")
            if (isHyphenated) {
                const [firstHalf, secondHalf] = currentText.split("-")
                fullSearchValue = `${firstHalf}-${prefixPaddedValues(
                    secondHalf,
                    firstHalf
                )}`
            } else {
                fullSearchValue = `E-${prefixPaddedValues(currentText)}`
            }
            setGeneratedCaseId(fullSearchValue)
        }
    }

    const onSearchClear = () => {
        setCurrentText("")
        setGeneratedCaseId("")
        getCaseHistoryData()
    }

    const copyData = (data) => {
        navigator.clipboard.writeText(data)
        setUpdatedContactId("")
        notify(`Successfully copied contact ID to clipboard`, "success")
    }

    const caseHistoryRequestTypeSwitch = () => {
        switch (previousRequestType) {
            case "duration":
                getCaseHistoryData()
                break
            default:
                getSearchResults(generatedCaseId)
        }
    }

    const changeArrayToPickerInput = (array = []) => {
        return array.map((value) => ({ name: value }))
    }

    const handleSelectedStateChange = (key, selected) => {
        // console.log({ key, selected })
        setSelectedCaseContactDetail((prev) => ({
            ...prev,
            ...{ [key]: selected },
        }))
    }

    useEffect(() => {
        if (updatedContactId == "") {
            caseHistoryRequestTypeSwitch()
        }
    }, [updatedContactId])

    useEffect(() => {
        const timer = setTimeout(() => {
            // call to a function
            if (generatedCaseId) {
                getSearchResults(generatedCaseId)
            }
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [generatedCaseId])

    const loadMore = (e) => {
        if (nextToken) {
            setIsLoading(true)
            getCaseContactList(
                prefferedTimeRange,
                prefferedNumberRange,
                instance,
                nextToken,
                user.userid
            )
                .then((resp) => {
                    setNextToken(resp.nextToken || null)
                    setFilteredList((pre) => [...resp.contacts, ...pre])
                })
                .catch((err) => {
                    console.log({ err })
                    notify(err?.message || "Something Went Wrong!", "error")
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            notify("No New Records!", "error")
        }
    }

    // FirstLoad Data Set
    const firstLoadDataLoad = () => {
        setIsLoading(true)
        getDefaultInstance(user.userid)
            .then((resp) => {
                setIsLoading(false)
                setInstance(resp)
                getCaseHistoryData(resp)
            })
            .catch((err) => {
                console.log({ err })
            })
    }

    useEffect(() => {
        firstLoadDataLoad()
        // console.log({newPermissions})
        // console.log({allPermissions})
    }, [])

    return (
        <>
            <div
                className={isLoading ? "display-block" : "display-none"}
                style={{
                    paddingTop: "10%",
                    paddingLeft: "45%",
                    position: "absolute",
                    zIndex: "10",
                }}
            >
                <ProgressCircle
                    aria-label="Loading…"
                    isIndeterminate
                    size="L"
                />
            </div>
            <TableViewContainer>
                <View UNSAFE_style={{ backgroundColor: "#FCFCFC" }}>
                    <Flex
                        direction={"row"}
                        alignItems={"center"}
                        alignContent={"center"}
                        justifyContent={"space-between"}
                        height={"81px"}
                        UNSAFE_style={{
                            backgroundColor: "#F8F8F8",
                            padding: "20px",
                        }}
                    >
                        <Text
                            UNSAFE_style={{
                                fontSize: "28px",
                                fontWeight: "500",
                            }}
                        >
                            Case History
                        </Text>
                        <View>
                            <Flex
                                gap={"size-300"}
                                direction={"row"}
                                justifyContent={"end"}
                                alignContent={"center"}
                                alignItems={"center"}
                            >
                                <Flex direction={"row"}>
                                    <SearchField
                                        width={"280px"}
                                        height={"32px"}
                                        placeholder="Search case"
                                        onClear={() => onSearchClear()}
                                        aria-label="Search"
                                        onChange={(value) =>
                                            setCurrentText(value)
                                        }
                                        onKeyDown={keyEventTrigger}
                                    />
                                </Flex>
                                <NumberField
                                    value={prefferedNumberRange}
                                    isQuiet
                                    minValue={1}
                                    maxValue={30}
                                    onChange={setPrefferedNumberRange}
                                    width={"10%"}
                                    aria-label="Numeric Preferrence"
                                />
                                <ActionGroup
                                    isEmphasized
                                    selectionMode="single"
                                    disallowEmptySelection
                                    selectedKeys={[prefferedTimeRange]}
                                    onAction={setPrefferedTimeRange}
                                    UNSAFE_className="ActionGroup_CursorPointer"
                                >
                                    <Item key="hour" aria-label="Brush">
                                        Hour
                                    </Item>
                                    <Item key="day" aria-label="Select">
                                        Day
                                    </Item>
                                    <Item key="week" aria-label="Select">
                                        Week
                                    </Item>
                                    <Item
                                        key="month"
                                        aria-label="Select Region"
                                    >
                                        Month
                                    </Item>
                                </ActionGroup>
                                <Button
                                    UNSAFE_className="cursorPointer"
                                    type="submit"
                                    onPress={() => getCaseHistoryData()}
                                >
                                    <Refresh />
                                </Button>
                            </Flex>
                        </View>
                    </Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        direction="column"
                        UNSAFE_style={{ padding: "15px" }}
                    >
                        <TableView
                            aria-label="Case History"
                            selectionMode="none"
                            // renderEmptyState={renderEmptyState}
                            height="70vh"
                            density="spacious"
                            overflowMode="wrap"
                            UNSAFE_className="custom-table"
                        >
                            <TableHeader columns={columns}>
                                {(column) => (
                                    <Column
                                        key={column.key}
                                        align={"start"}
                                        minWidth={column.minWidth}
                                    >
                                        <Flex direction="column" gap="10px">
                                            <div
                                                onClick={() =>
                                                    handleSortChange(column.key)
                                                }
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Text>{column.name}</Text>
                                                {sortDescriptor.column ===
                                                column.key ? (
                                                    sortDescriptor.direction ===
                                                    "ascending" ? (
                                                        <UpArrow />
                                                    ) : (
                                                        <DownArrow />
                                                    )
                                                ) : null}
                                            </div>
                                            {[
                                                "contact_creation_time",
                                                "contact_disconnect_time",
                                            ].includes(column.key) ? (
                                                <DatePicker
                                                    value={
                                                        dateFilters[column.key]
                                                    }
                                                    onChange={(date) =>
                                                        handleDateChange(
                                                            column.key,
                                                            date
                                                        )
                                                    }
                                                    aria-label={`Search ${column.name}`}
                                                    width="100%"
                                                />
                                            ) : "status" == column.key ? (
                                                <Picker
                                                    aria-label={`Status`}
                                                    width="150px"
                                                    value={selectedStatus}
                                                    onSelectionChange={
                                                        setSelectedStatus
                                                    }
                                                    items={statusList}
                                                    onChange
                                                >
                                                    {(item) => (
                                                        <Item key={item.name}>
                                                            {item.name}
                                                        </Item>
                                                    )}
                                                </Picker>
                                            ) : (
                                                <SearchField
                                                    aria-label={`Search ${column.name}`}
                                                    placeholder={`Search ${column.name}`}
                                                    value={filters[column.key]}
                                                    onChange={(value) =>
                                                        handleFilterChange(
                                                            column.key,
                                                            value
                                                        )
                                                    }
                                                    width="100%"
                                                />
                                            )}
                                        </Flex>
                                    </Column>
                                )}
                            </TableHeader>
                            <TableBody>
                                {filteredData.length > 0 ? (
                                    filteredData.map((item, index) => (
                                        <Row key={index}>
                                            {(columnKey) => {
                                                return getCell(columnKey, item)
                                            }}
                                        </Row>
                                    ))
                                ) : (
                                    <Row>
                                        {columns.map((column, index) => (
                                            <Cell key={index}>
                                                {index === 0 ? (
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "16px",
                                                        }}
                                                    >
                                                        No Data Found
                                                    </div>
                                                ) : null}
                                            </Cell>
                                        ))}
                                    </Row>
                                )}
                            </TableBody>
                        </TableView>
                        <ActionGroup
                            marginTop={"10px"}
                            aria-label="Next"
                            selectionMode="none"
                            onAction={loadMore}
                        >
                            <Item key="next">Next</Item>
                        </ActionGroup>
                    </Flex>
                </View>
            </TableViewContainer>
            <DialogTrigger
                type="fullscreenTakeover"
                mobileType="modal"
                isOpen={showDetailsModal}
                isDismissable
                onOpenChange={setShowDetailsModal}
            >
                <></>
                <Dialog isDismissable>
                    <View
                        UNSAFE_style={{ width: "100vw" }}
                        backgroundColor={"gray-200"}
                        height={"100vh"}
                    >
                        <Header
                            UNSAFE_style={{
                                width: "100%",
                                justifyContent: "space-between",
                                backgroundColor: "#F8F8F8",
                                padding: "10px 24px",
                            }}
                        >
                            <Flex
                                width={"100%"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Heading level={2} margin={"5px 0px"}>
                                    Zeon Test ( {selectedCaseId} )
                                </Heading>
                                <Flex gap={"10px"}>
                                    <Button
                                        onPress={() =>
                                            setShowDetailsModal((prev) => !prev)
                                        }
                                        UNSAFE_style={{
                                            backgroundColor: "#E6E6E6",
                                            color: "black",
                                            border: "none",
                                        }}
                                    >
                                        Back
                                    </Button>
                                    <DialogTrigger>
                                        <ActionButton
                                            UNSAFE_className="ActionButtonBlue"
                                            isDisabled={
                                                isLoading ||
                                                !havingEditPermission
                                            }
                                        >
                                            Re-Route
                                        </ActionButton>
                                        <AlertDialog
                                            variant="confirmation"
                                            title="Confirm Re-Route"
                                            primaryActionLabel="Re-Route"
                                            cancelLabel="Cancel"
                                            onPrimaryAction={retryCaseConnect}
                                        >
                                            Are you sure you want to Re-Route
                                            Case?
                                        </AlertDialog>
                                    </DialogTrigger>
                                </Flex>
                            </Flex>
                        </Header>
                        <Content>
                            <View padding={"32px"} paddingTop={"12px"}>
                                <Flex
                                    gap={"32px"}
                                    width="100%"
                                    // justifyContent={"space-between"}
                                >
                                    <View width={"64%"}>
                                        <Heading
                                            level={3}
                                            UNSAFE_style={{
                                                marginBottom: "16px",
                                            }}
                                        >
                                            Case details
                                        </Heading>
                                        <View
                                            backgroundColor={"static-white"}
                                            padding={"20px"}
                                            borderRadius={"medium"}
                                            height={"490px"}
                                        >
                                            <Flex
                                                direction={"column"}
                                                gap={"size-300"}
                                            >
                                                {/* <View>
                                                <Heading level={5}>Case Description</Heading>
                                                <Text>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                                </Text>
                                            </View> */}

                                                <Flex gap={"size-1200"}>
                                                    <LabeledValue
                                                        label={
                                                            <Heading
                                                                level={5}
                                                                UNSAFE_style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                Product Code
                                                            </Heading>
                                                        }
                                                        value={
                                                            selectedCaseContactDetail?.PRODUCTCODE
                                                        }
                                                    />

                                                    <LabeledValue
                                                        label={
                                                            <Heading
                                                                level={5}
                                                                UNSAFE_style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                CRM Queue
                                                            </Heading>
                                                        }
                                                        value={
                                                            selectedCaseContactDetail?.CRMQUEUE ||
                                                            "-"
                                                        }
                                                    />
                                                    <LabeledValue
                                                        label={
                                                            <Heading
                                                                level={5}
                                                                UNSAFE_style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                Product Name
                                                            </Heading>
                                                        }
                                                        value={
                                                            selectedCaseContactDetail?.productname ||
                                                            "-"
                                                        }
                                                    />

                                                    {/* <View width={"size-2000"}>
                                                    <Flex direction={"column"}>
                                                        <Heading level={5}>Preferred TST</Heading>
                                                        <Text>-</Text>
                                                    </Flex>
                                                </View> */}
                                                </Flex>
                                                <Flex
                                                    width={"100%"}
                                                    direction={"row"}
                                                    alignContent={"start"}
                                                    gap={"20px"}
                                                >
                                                    <Flex
                                                        width={"50%"}
                                                        direction={"column"}
                                                    >
                                                        <View>
                                                            <strong>
                                                                Priority
                                                            </strong>
                                                        </View>
                                                        <View>
                                                            <Picker
                                                                width={"100%"}
                                                                aria-label="PRIORITYTEXT"
                                                                items={
                                                                    priorityList
                                                                }
                                                                selectedKey={
                                                                    selectedCaseContactDetail?.PRIORITYTEXT
                                                                }
                                                                onSelectionChange={(
                                                                    selected
                                                                ) =>
                                                                    handleSelectedStateChange(
                                                                        "PRIORITYTEXT",
                                                                        selected
                                                                    )
                                                                }
                                                                // isDisabled={
                                                                //     !permissions.includes(
                                                                //         SRSRoles.caseHistoryAdmin
                                                                //     )
                                                                // }
                                                            >
                                                                {(item) => (
                                                                    <Item
                                                                        key={
                                                                            item.name
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </Item>
                                                                )}
                                                            </Picker>
                                                        </View>
                                                    </Flex>
                                                    <Flex
                                                        width={"50%"}
                                                        direction={"column"}
                                                        alignContent={"start"}
                                                    >
                                                        <View>
                                                            <strong>
                                                                Support Level
                                                            </strong>
                                                        </View>
                                                        <View>
                                                            <Picker
                                                                width={"100%"}
                                                                aria-label="LEVEL"
                                                                items={
                                                                    levelList
                                                                }
                                                                selectedKey={
                                                                    selectedCaseContactDetail?.LEVEL
                                                                }
                                                                onSelectionChange={(
                                                                    selected
                                                                ) =>
                                                                    handleSelectedStateChange(
                                                                        "LEVEL",
                                                                        selected
                                                                    )
                                                                }
                                                                isDisabled={
                                                                    // !permissions.includes(
                                                                    //     SRSRoles.caseHistoryAdmin
                                                                    // )
                                                                    true
                                                                }
                                                            >
                                                                {(item) => (
                                                                    <Item
                                                                        key={
                                                                            item.name
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </Item>
                                                                )}
                                                            </Picker>
                                                        </View>
                                                    </Flex>
                                                </Flex>
                                                <Flex
                                                    width={"100%"}
                                                    direction={"row"}
                                                    alignContent={"start"}
                                                    gap={"20px"}
                                                >
                                                    <Flex
                                                        width={"50%"}
                                                        direction={"column"}
                                                    >
                                                        <View>
                                                            <strong>
                                                                Issue Reason
                                                            </strong>
                                                        </View>
                                                        <View>
                                                            <Picker
                                                                width={"100%"}
                                                                aria-label="ISSUEREASONTEXT"
                                                                items={
                                                                    issueReasonList
                                                                }
                                                                selectedKey={
                                                                    selectedCaseContactDetail?.ISSUEREASONTEXT
                                                                }
                                                                onSelectionChange={(
                                                                    selected
                                                                ) =>
                                                                    handleSelectedStateChange(
                                                                        "ISSUEREASONTEXT",
                                                                        selected
                                                                    )
                                                                }
                                                                // isDisabled={
                                                                //     !permissions.includes(
                                                                //         SRSRoles.caseHistoryAdmin
                                                                //     )
                                                                // }
                                                            >
                                                                {(item) => (
                                                                    <Item
                                                                        key={
                                                                            item.name
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </Item>
                                                                )}
                                                            </Picker>
                                                        </View>
                                                    </Flex>
                                                    <Flex
                                                        width={"50%"}
                                                        direction={"column"}
                                                        alignContent={"start"}
                                                    >
                                                        <View>
                                                            <strong>
                                                                Region
                                                            </strong>
                                                        </View>
                                                        <View>
                                                            <Picker
                                                                width={"100%"}
                                                                aria-label="Region"
                                                                items={
                                                                    regionList
                                                                }
                                                                selectedKey={
                                                                    selectedCaseContactDetail?.REGIONTEXT?.toLowerCase()
                                                                }
                                                                onSelectionChange={(
                                                                    selected
                                                                ) =>
                                                                    handleSelectedStateChange(
                                                                        "REGIONTEXT",
                                                                        selected
                                                                    )
                                                                }
                                                                // isDisabled={
                                                                //     // !permissions.includes(
                                                                //     //     SRSRoles.caseHistoryAdmin
                                                                //     // )
                                                                //     true
                                                                // }
                                                                isDisabled={
                                                                    true
                                                                }
                                                            >
                                                                {(item) => (
                                                                    <Item
                                                                        key={
                                                                            item.name.toLowerCase()
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </Item>
                                                                )}
                                                            </Picker>
                                                        </View>
                                                    </Flex>
                                                </Flex>
                                                <Flex
                                                    width={"100%"}
                                                    direction={"row"}
                                                    alignContent={"start"}
                                                    gap={"20px"}
                                                >
                                                    <TextArea
                                                        minWidth={"100%"}
                                                        height={"200px"}
                                                        // maxHeight={"300px"}
                                                        label={
                                                            <View
                                                                UNSAFE_style={{
                                                                    color:
                                                                        "#2f2f2f",
                                                                    fontWeight:
                                                                        "800",
                                                                    margin: "0",
                                                                    padding:
                                                                        "0",
                                                                }}
                                                            >
                                                                <strong>
                                                                    Contact
                                                                    Details
                                                                </strong>
                                                            </View>
                                                        }
                                                        value={`AIC AGENT ACTIVITY : ${selectedCaseContactDetail?.AIC_AGENT_ACTIVITY} \n \n AIC CONTACT HISTORY: ${selectedCaseContactDetail?.AIC_CONTACT_HISTORY}`}
                                                    />
                                                </Flex>
                                            </Flex>
                                        </View>
                                    </View>
                                    <View width={"35%"}>
                                        <Heading
                                            level={3}
                                            UNSAFE_style={{
                                                marginBottom: "16px",
                                            }}
                                        >
                                            Contact details
                                        </Heading>
                                        <View
                                            backgroundColor={"static-white"}
                                            height={"490px"}
                                            padding={"20px"}
                                            borderRadius={"medium"}
                                        >
                                            <Flex
                                                direction={"column"}
                                                gap={"size-300"}
                                            >
                                                <LabeledValue
                                                    label={
                                                        <Heading
                                                            level={5}
                                                            UNSAFE_style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            Contact Id
                                                        </Heading>
                                                    }
                                                    value={
                                                        selectedCaseContactDetail?.aws_contact_id
                                                    }
                                                />

                                                <Flex
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                >
                                                    <View width={"40%"}>
                                                        <LabeledValue
                                                            label={
                                                                <Heading
                                                                    level={5}
                                                                    UNSAFE_style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    Case time
                                                                </Heading>
                                                            }
                                                            value={formatDateValue(
                                                                selectedCaseContactDetail?.contact_creation_time
                                                            )}
                                                        />
                                                    </View>
                                                    <View width={"40%"}>
                                                        <LabeledValue
                                                            label={
                                                                <Heading
                                                                    level={5}
                                                                    UNSAFE_style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    Case answer
                                                                    time
                                                                </Heading>
                                                            }
                                                            value={formatDateValue(
                                                                selectedCaseContactDetail?.contact_inQueue_time
                                                            )}
                                                        />
                                                    </View>
                                                </Flex>
                                                <Flex
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                >
                                                    <View width={"40%"}>
                                                        <LabeledValue
                                                            label={
                                                                <Heading
                                                                    level={5}
                                                                    UNSAFE_style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    Case
                                                                    disconnect
                                                                    time
                                                                </Heading>
                                                            }
                                                            value={formatDateValue(
                                                                selectedCaseContactDetail?.contact_disconnect_time
                                                            )}
                                                        />
                                                    </View>
                                                    <View width={"40%"}>
                                                        <LabeledValue
                                                            label={
                                                                <Heading
                                                                    level={5}
                                                                    UNSAFE_style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    Case
                                                                    Answered By
                                                                </Heading>
                                                            }
                                                            value={
                                                                selectedCaseContactDetail?.contact_answered_by ||
                                                                "-"
                                                            }
                                                        />
                                                    </View>
                                                </Flex>
                                            </Flex>
                                        </View>
                                    </View>
                                </Flex>
                            </View>
                        </Content>
                    </View>
                </Dialog>
            </DialogTrigger>
            <DialogTrigger
                type="modal"
                mobileType="modal"
                isOpen={!!updatedContactId}
                isDismissable
                onOpenChange={setUpdatedContactId}
            >
                <></>
                <Dialog size="M" isDismissable>
                    <Header justifySelf={"flex-start"}>
                        <Heading level={4}>
                            Successfully Re-Routed Case ({selectedCaseId})
                        </Heading>
                    </Header>
                    <Divider />
                    <Content>
                        <Flex
                            direction={"row"}
                            gap={"size-100"}
                            justifyContent={"center"}
                            alignContent={"center"}
                        >
                            <View UNSAFE_className="textVerticallyCenter">
                                <Flex direction={"column"}>
                                    <strong>Updated Contact ID : </strong>
                                    {updatedContactId}
                                </Flex>
                            </View>
                            <Divider size="S" orientation="vertical" />
                            <View UNSAFE_className="textVerticallyCenter">
                                <Button
                                    aria-label="copy contact button"
                                    onPress={() => copyData(updatedContactId)}
                                    UNSAFE_className="cursorPointer"
                                >
                                    <Copy />
                                </Button>
                            </View>
                        </Flex>
                    </Content>
                </Dialog>
            </DialogTrigger>
        </>
    )
}

export default connect(mapStateToProps)(CaseHistory)
