const TableViewContainer = (props) => {
    return (      <div
        style={{
          padding: '25px',
          marginTop: '10px',
          width: '90vw',
        }}
      >{props.children}</div>)
}

export default TableViewContainer;